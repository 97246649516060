import { ReactElement, ReactNode } from "react";
import {
  LookupMethod,
  DoNotCare,
  isIdType,
  IdType,
  IdTypeFromData,
  isReactNode
} from "./types";
import { toArray } from "../utils/objects";

export * from "./Input/Date/utils";
export { makeTable } from "./Table/makeTable";

/**
 * @param option: an option to examine.
 * @return
 *   - if an object, a JSON stringified version of the object
 *   - if an array, join the values separating with a comma
 *   - if undefined, the empty string
 *   - else, option.toString
 */
export const defaultLookup: LookupMethod<string> = (
  option?: DoNotCare
): string => {
  if (typeof option === "undefined") {
    return "";
  }

  if (Array.isArray(option)) {
    return option.join(", ");
  }

  return typeof option === "object"
    ? JSON.stringify(option)
    : option.toString();
};

/**
 * @param min: the minimum value.
 * @param value: the value to constrain.
 * @param max: the maximum value.
 * @return: the value, constrained to the inclusive range of [min, max].
 */
export const constrain = (min: number, value: number, max: number) => {
  return Math.max(Math.min(Number(value), max), min);
};

export const defaultLookupId = <
  Data,
  Returns extends IdType = IdTypeFromData<Data>
>(
  data: Data,
  keys: string[] = ["id", "key"]
) => {
  let res: unknown;
  if (isIdType(data)) {
    res = data;
  }
  if (!res && data && typeof data === "object") {
    for (const key of keys) {
      if (key in data && isIdType(data[key as keyof Data])) {
        res = data[key as keyof Data];
        break;
      }
    }
  }
  if (!isIdType(res)) {
    res = JSON.stringify(data);
  }
  return res as Returns;
};

export const defaultLookupLabel = <Data, Returns extends ReactNode = ReactNode>(
  data: Data,
  keys = ["label", "title", "name"]
) => {
  let res: unknown;
  if (isReactNode(data)) {
    res = data;
  } else if (
    (res === undefined || res === null) &&
    data &&
    typeof data === "object"
  ) {
    for (const key of keys) {
      if (key in data && isReactNode(data[key as keyof Data])) {
        res = data[key as keyof Data];
        break;
      }
    }
  }

  if (!isReactNode(res)) {
    res = JSON.stringify(data);
  }

  return res as Returns;
};

/**
 * Lookup an element by their displayName
 * @returns an array of react elements that match the display name
 */
export const lookupElementsByDisplayName = (
  displayName: string,
  children: DoNotCare
) =>
  (Array.isArray(children) ? children : [children]).filter(
    child => child && child?.type?.displayName === displayName
  ) as Array<ReactElement<DoNotCare>>;

/**
 * Deep lookup an element (by their displayName) with the ability to
 * look multiple levels deep.
 * @returns an array of react elements that match the display name
 */
export function deepLookupElementsByDisplayName(
  displayName: string,
  initialChildren: DoNotCare,
  opts: {
    /**
     * How many levels deep should we look for the component
     * @default 2
     */
    depth?: number;
    /**
     * Should the full depth be completed if component(s) found before full depth is reached
     * @default false
     */
    completeDepth?: boolean;
    /**
     * Name of prop to look in
     * @default "children"
     */
    lookIn?: string;
    /**
     * Limit which elements to search in, by providing a set of displayNames.
     * If not provided, all elements will be searched.
     */
    parentNames?: Set<string>;
  } = {}
) {
  const {
    depth = 2,
    completeDepth = false,
    lookIn = "children",
    parentNames = new Set()
  } = opts;

  if (depth < 0) {
    return [];
  }

  const found: ReactElement[] = [];

  found.push(...lookupElementsByDisplayName(displayName, initialChildren));

  if (!depth || (found?.length && !completeDepth)) {
    return found;
  }

  const childArr = toArray(initialChildren);

  childArr.forEach(child => {
    if (parentNames.size && !parentNames.has(child?.type?.displayName)) {
      return;
    }

    if (child.props?.[lookIn]) {
      found.push(
        ...deepLookupElementsByDisplayName(displayName, child.props[lookIn], {
          ...opts,
          depth: depth - 1
        })
      );
    }
  });

  return found;
}

/**
 * Generate a CSV formatted Blob from an array of tabular data
 * @param data Tabular data array
 */
export const CsvBlob = (data: any[][]) => {
  const lines = [];

  for (const row of data) {
    lines.push(
      row
        .map(cell => {
          if (typeof cell === "number") return cell;
          return JSON.stringify(cell).replaceAll(/\\"/g, '""');
        })
        .join(",") + "\r\n"
    );
  }

  return new Blob(lines, { type: "text/csv;charset=utf-8;" });
};

/**
 * Start a download of a url link
 * @param url Download link
 * @param filename Name of the download file to save
 */
export const startDownload = (url: string, filename: string) => {
  const linkElement = document.createElement("a");
  linkElement.download = filename;
  linkElement.href = url;

  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
};
