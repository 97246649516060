import { ComponentProps } from "react";
import Button, { ButtonVariant } from "../Button";
import { DerivedColumnDef } from "./hooks/helpers/ColumnDefinition";
import { ColumnKeys, TableRowType } from "./types";
import { CsvBlob, startDownload } from "../utils";

export const EXPORT_TABLE_BUTTON_DISPLAY_NAME = "ExportTableButton";

/**
 * `ExportTableButton` is a button that generates a CSV from table data.
 */
export const ExportTableButton = <
  Row extends TableRowType,
  Column extends string
>({
  label = "Export",
  filename = "Madhive_Table.csv",
  variant = "ghost",
  orderedKeys,
  columnDefs,
  data
}: {
  /**
   * The label of the button.
   * @default Export
   */
  label?: string;
  /**
   * The name to use for the file that is downloaded.
   * @default Madhive_Table.csv
   */
  filename?: string;
  /**
   * The Button variant.
   * @default ghost
   */
  variant?: ButtonVariant;
  /**
   * The ordered keys of the columns.
   * Note: This will be derived automatically this component is a child of a `Table` component.
   */
  orderedKeys?: ColumnKeys<Row, Column>[];
  /**
   * The column definitions
   * Note: This will be derived automatically this component is a child of a `Table` component.
   */
  columnDefs?: { [k in Column]: DerivedColumnDef<Row, Column> };
  /**
   * The data as Rows.
   * Note: This will be derived automatically this component is a child of a `Table` component.
   */
  data?: Row[];
}) => {
  const handleClick = () => {
    if (!orderedKeys || !columnDefs || !data) return;

    const csvData = [
      orderedKeys.map((columnKey: Column) => columnDefs[columnKey].label)
    ].concat(
      data.map(row =>
        orderedKeys.map((columnKey: Column) =>
          columnDefs[columnKey].render(row)
        )
      )
    );

    startDownload(URL.createObjectURL(CsvBlob(csvData)), filename);
  };
  return (
    <Button variant={variant} prefix="Download" onClick={handleClick}>
      {label}
    </Button>
  );
};

export type ExportTableButtonProps = ComponentProps<typeof ExportTableButton>;

ExportTableButton.displayName = EXPORT_TABLE_BUTTON_DISPLAY_NAME;
