import { css } from "@emotion/react";
import {
  ChangeEvent,
  DragEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { UploaderProps, FileError, ClearableInputHandle } from "./types";
import { styles } from "./styles";
import { UploaderErrors } from "./constants";
import UploaderFileList from "./FileList";
import EmptyContent from "./EmptyContent";
import FormField from "../FormField";
import { validateAcceptedFile } from "./utils";

/**
 * `Uploader` allows users to upload files via the local file system or by dragging and dropping files into the component.
 */
const Uploader = forwardRef<ClearableInputHandle, UploaderProps>(
  (
    {
      multiple = false,
      onChange,
      accepts,
      error = "",
      maxFileSize = Number.MAX_VALUE
    },
    ref
  ) => {
    const [isEmpty, setIsEmpty] = useState<boolean>(true);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const [uploadedFiles, setUploadedFiles] = useState<Map<string, File>>(
      new Map<string, File>()
    );
    const [fileErrors, setFileErrors] = useState<Map<string, FileError>>(
      new Map<string, FileError>()
    );
    const [successFiles, setSuccessFiles] = useState<Map<string, File>>(
      new Map<string, File>()
    );
    const [processingFiles, setProcessingFiles] = useState<Map<string, File>>(
      new Map<string, File>()
    );

    const [reselectingFile, setReselectingFile] = useState<File | null>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    const acceptedExtensions = accepts.filter(str => str[0] === ".");
    const acceptedTypes = accepts.filter(str => str[0] !== ".");

    /**
     * Returns a copy of the set in the previous file state with the provided file removed.
     * @param file The file to remove from the set.
     * @param currentFileState The current file state.
     * @returns the updated file state with the target file removed.
     */
    const deleteFromFileSet = (
      file: File,
      currentFileState: Map<string, File>
    ) => {
      const nextFileState = new Map<string, File>(Array.from(currentFileState));
      nextFileState.delete(file.name);
      return nextFileState;
    };

    const updateProcessingFiles = (file: File) => {
      setProcessingFiles((currentProcessingFiles: Map<string, File>) =>
        deleteFromFileSet(file, currentProcessingFiles)
      );
    };

    const onDelete = (file: File) => {
      const newUploadedFiles = deleteFromFileSet(file, uploadedFiles);
      const newSuccessFiles = deleteFromFileSet(file, successFiles);
      const newProcessingFiles = deleteFromFileSet(file, processingFiles);
      const newFileErrors = new Map<string, FileError>(Array.from(fileErrors));
      if (newFileErrors.has(file.name)) {
        newFileErrors.delete(file.name);
      }
      setUploadedFiles(newUploadedFiles);
      setFileErrors(newFileErrors);
      setSuccessFiles(newSuccessFiles);
      setProcessingFiles(newProcessingFiles);
      if (
        newUploadedFiles.size !== uploadedFiles.size ||
        newFileErrors.size !== fileErrors.size ||
        newProcessingFiles.size !== processingFiles.size ||
        newSuccessFiles.size !== successFiles.size
      ) {
        onChange(newSuccessFiles, newFileErrors);
      }
    };

    const onFileUpload = (
      event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>
    ) => {
      const fileList =
        (event as ChangeEvent<HTMLInputElement>).target.files ||
        (event as DragEvent<HTMLDivElement>).dataTransfer?.files;
      if (!fileList) {
        return;
      }

      setIsEmpty(!fileList.length);

      const nextUploadedFiles = multiple
        ? new Map<string, File>(Array.from(uploadedFiles))
        : new Map<string, File>();
      const nextFileErrors = multiple
        ? new Map<string, FileError>(Array.from(fileErrors))
        : new Map<string, FileError>();
      const nextProcessingFiles = multiple
        ? new Map<string, File>(Array.from(processingFiles))
        : new Map<string, File>();
      const nextSuccessFiles = multiple
        ? new Map<string, File>(Array.from(successFiles))
        : new Map<string, File>();

      if (reselectingFile) {
        nextUploadedFiles.delete(reselectingFile.name);
        nextFileErrors.delete(reselectingFile.name);
      }

      for (let i = 0; i < (multiple ? fileList.length : 1); i++) {
        if (
          accepts.length &&
          !validateAcceptedFile(fileList[i], {
            extensions: acceptedExtensions,
            types: acceptedTypes
          })
        ) {
          nextFileErrors.set(fileList[i].name, {
            file: fileList[i],
            error: UploaderErrors.UNSUPPORTED_FILE_TYPE
          });
        } else if (fileList[i].size > maxFileSize) {
          nextFileErrors.set(fileList[i].name, {
            file: fileList[i],
            error: `${UploaderErrors.FILE_SIZE} of ${maxFileSize} bytes`
          });
        } else {
          !nextUploadedFiles.has(fileList[i].name) &&
            nextProcessingFiles.set(fileList[i].name, fileList[i]);
          nextUploadedFiles.set(fileList[i].name, fileList[i]);
        }
      }

      setUploadedFiles(nextUploadedFiles);
      setFileErrors(nextFileErrors);
      setProcessingFiles(nextProcessingFiles);
      setSuccessFiles(nextSuccessFiles);
      setReselectingFile(null);
      setIsDragging(false);

      if (inputRef.current) {
        inputRef.current.value = "";
      }
    };

    const onFileDrop = (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      onFileUpload(event);
    };

    const onReselect = (file: File) => {
      setReselectingFile(file);
      inputRef.current?.click();
    };

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (
        !uploadedFiles.size &&
        !processingFiles.size &&
        !fileErrors.size &&
        !successFiles.size
      ) {
        inputRef.current?.focus();
        setIsEmpty(true);
        return;
      }
      if (!processingFiles.size && !isEmpty) {
        onChange(successFiles, fileErrors);
      }
      containerRef.current
        ?.querySelector<HTMLButtonElement>("button:last-of-type")
        ?.focus();
    }, [uploadedFiles, processingFiles, fileErrors, successFiles]);

    useEffect(() => {
      if (!processingFiles.size) {
        return;
      }

      processingFiles.forEach((file: File) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onerror = event => {
          setFileErrors(previousErrors =>
            new Map<string, FileError>(Array.from(previousErrors)).set(
              file.name,
              {
                file,
                error: `${UploaderErrors.READING_ERROR}\n${JSON.stringify(
                  event
                )}`
              }
            )
          );
          updateProcessingFiles(file);
        };
        reader.onload = () => {
          setSuccessFiles(previousSuccessFiles =>
            new Map<string, File>(Array.from(previousSuccessFiles)).set(
              file.name,
              file
            )
          );
          updateProcessingFiles(file);
        };
      });
    }, [uploadedFiles, successFiles, fileErrors]);

    useImperativeHandle(ref, () => {
      return {
        clearFiles() {
          const newSuccessFiles = new Map<string, File>();
          const newFileErrors = new Map<string, FileError>();
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          setIsEmpty(true);
          setUploadedFiles(new Map<string, File>());
          setFileErrors(newFileErrors);
          setSuccessFiles(newSuccessFiles);
          setProcessingFiles(new Map<string, File>());
          setReselectingFile(null);
          onChange(newSuccessFiles, newFileErrors);
        }
      };
    });

    return (
      <FormField error={!isEmpty ? error : undefined} fill>
        <div
          className="kit-Uploader"
          ref={containerRef}
          css={[
            styles.container,
            isDragging && styles.dragging,
            error.length && styles.containerError
          ]}
          onClick={({ currentTarget }) => {
            currentTarget.blur();
            inputRef.current?.click();
          }}
          onKeyDown={event =>
            (event.key === "Enter" || event.key === " ") &&
            inputRef.current?.click()
          }
          onDragLeave={() => setIsDragging(false)}
          onDragOver={event => {
            event.preventDefault();
            setIsDragging(true);
          }}
          onDrop={onFileDrop}
          role="button"
          tabIndex={0}
          aria-label="file uploader"
        >
          <div css={styles.contentWrapper}>
            {isEmpty ? (
              <EmptyContent accepts={accepts} multiple={multiple} />
            ) : (
              <>
                <UploaderFileList
                  files={fileErrors}
                  onDelete={onDelete}
                  onReselect={onReselect}
                />
                <UploaderFileList
                  files={processingFiles}
                  onDelete={onDelete}
                  processing
                />
                <UploaderFileList files={successFiles} onDelete={onDelete} />
              </>
            )}
            {error.length && isEmpty ? (
              <span css={styles.uploaderError}>{error}</span>
            ) : null}
            <input
              ref={inputRef}
              type="file"
              multiple={multiple && !reselectingFile}
              accept={accepts.join(",")}
              css={css`
                display: none;
              `}
              onChange={onFileUpload}
            />
          </div>
        </div>
      </FormField>
    );
  }
);

export default Uploader;
export * from "./types";
